<template>

  <div class="flex flex-col justify-center py-12 sm:px-6 lg:px-8">

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <h2
          class="text-gray-700 font-medium text-center mb-2"
        >Create your account</h2>
        <h3 class="text-gray-700 text-sm text-center mb-2">Start your free trial</h3>

        <p class="text-center text-sm text-green-700 m-2">
          Join <strong>312</strong> users who started using Kairo in November.
        </p>

        <div v-if="msgError" class="rounded-md bg-red-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: x-circle -->
              <svg class="h-5 w-5 text-red-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-red-800">
                There was a problem!
              </h3>
              <div class="mt-2 text-sm text-red-700">
                {{msgError}}
              </div>
            </div>
          </div>
        </div>
        <div v-if="msgSuccess" class="rounded-md bg-green-50 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: x-circle -->
              <svg class="h-5 w-5 text-green-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-green-800">
                Please check your email!
              </h3>
              <div class="mt-2 text-sm text-green-700">
                {{msgSuccess}}
              </div>
            </div>
          </div>
        </div>

        <div>
          <Form
            @submit="signup()"
            class="space-y-6"
          >
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div class="mt-1">
                <Field
                  id="name"
                  name="name"
                  v-model="crendentials.name"
                  type="text"
                  :rules="isRequired"
                  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <ErrorMessage name="name" class="mt-2 text-sm text-red-600" />
              </div>
            </div>
            <div>
              <label for="email" class="block text-sm font-medium text-gray-700">
                Email address
              </label>
              <div class="mt-1">
                <Field
                  id="email"
                  v-model="crendentials.email"
                  type="email"
                  name="email"
                  :rules="validateEmail"
                  placeholder="hey@email.com"
                  autocomplete="email" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <ErrorMessage name="email" class="mt-2 text-sm text-red-600" />
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <i :class="[passwordIcon]" @click="hidePassword = !hidePassword"></i>
                <Field
                        v-model="crendentials.password"
                        :type="passwordType"
                        id="password"
                        name="password"
                        :rules="validatePassword"
                        autocomplete="current-password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <ErrorMessage name="password" class="mt-2 text-sm text-red-600" />
              </div>
            </div>

            <div class="text-sm text-gray-700">
              By signing up, I accept the Kairo <router-link to="terms" class="underline">Terms of Service</router-link> and acknowledge the <router-link to="privacy-policy" class="underline">Privacy Policy</router-link>.
            </div>

            <div>
              <button
                :disabled="lockButtons"
                class="disabled:opacity-50 w-full border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
                style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
              >
                Start trial
              </button>
            </div>
          </Form>
        </div>

        <div class="mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm">
            <span class="px-2 bg-white text-gray-500">
            </span>
            </div>
          </div>
          <div>
            <div class="relative flex justify-center text-sm mt-4">
              <span class="px-2 bg-white text-gray-500">
                <router-link
                  to="/login"
                  class="font-medium text-indigo-600 hover:text-indigo-500"
                >Already have a Kairo account? Log in
                </router-link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <div class="flex justify-center space-x-6 md:order-2">
        <a href="https://twitter.com/kairohq" target="_blank" class="text-gray-400 hover:text-gray-500">
          <span class="sr-only">Twitter</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        </a>
      </div>
      <div class="mt-8 md:mt-0 md:order-1">
        <p class="text-left text-base text-gray-400">
          &copy; 2024 Sotak, Ltd. All rights reserved. Contact: support@getkairo.com<br />
          <router-link to="terms">Terms of Service</router-link> | <router-link to="privacy-policy">Privacy Policy</router-link> | <router-link to="acceptable-use-policy">Acceptable Use Policy</router-link>
        </p>
      </div>
    </div>
  </footer>

  <div v-if="isDevEnvironment" class="mt-6">
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
      <SetNetlifyURL />
    </div>
  </div>

</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import SetNetlifyURL from "../components/SetNetlifyURL.vue";
  import { Field, Form, ErrorMessage } from 'vee-validate';

  export default {
    components: {
      SetNetlifyURL,
      Field,
      Form,
      ErrorMessage
    },
    data() {
      return {
        crendentials: {
          name: "",
          password: "",
          email: ""
        },
        hidePassword: true,
        toggleModal: false,
        lockButtons: false,
        msgError: null,
        msgSuccess: null
      };
    },
    computed: {
      ...mapGetters("app", ["isDevEnvironment"]),
      passwordType() {
        return this.hidePassword ? "password" : "text";
      },
      passwordIcon() {
        return this.hidePassword ? "eye-open" : "eye-closed";
      },
    },
    methods: {
      ...mapActions("auth", [
        "attemptSignup",
      ]),
      signup() {
        this.msgError = '';
        this.msgSuccess = '';
        this.lockButtons = true;
        this.attemptSignup(this.crendentials)
          .then(() => {
            this.msgSuccess = `A Confirmation Email has been sent to you.`;
            this.lockButtons = false;
          })
          .catch(error => {
            this.msgError = `${error}`;
            this.lockButtons = false;
          });
      },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      validateEmail(value) {
        if (!value) {
          return 'This field is required';
        }
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)) {
          return 'This field must be a valid email';
        }
        return true;
      },
      validatePassword(value) {
        if (!value) {
          return 'This field is required';
        }
        if (value.length < 8) {
          return 'Password must have at least 8 characters';
        }
        return true;
      }
    }
  };
</script>
